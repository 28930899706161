import {StyleExtractor} from "@kubric/utils";
import React from "react";

import SanityRichText from "../../../commons/SanityRichText";
import * as styles from "./styles.module.scss";

const styler = new StyleExtractor(styles);

export default function WaitlistHero({data, formHandler}) {
  const {_rawWaitlistHeroHeading, _rawWaitlistHeroSubHeading, waitlistHeroCTA} =
    data;

  return (
    <div className={styler.get(["waitlistHeroWrapper"])}>
      <video autoPlay loop muted className={styler.get(["videoContainer"])}>
        <source
          src='https://media.kubric.io/api/assetlib/cf5fa0a8-dd91-4614-a9bd-51083f048bb8.webm'
          type='video/mp4'
        />
      </video>
      <div className={styler.get(["headingBox"])}>
        {/* Heading */}
        <div className={styler.get(["waitlistHeading"])}>
          <SanityRichText
            renderContainerOnSingleChild
            blocks={_rawWaitlistHeroHeading}
          />
        </div>

        {/* Subheading */}
        <div className={styler.get(["waitlistSubHeading"])}>
          <SanityRichText
            renderContainerOnSingleChild
            blocks={_rawWaitlistHeroSubHeading}
          />
        </div>

        <button
          onClick={formHandler}
          type='button'
          className={styler.get(["waitlistHeroCTA"])}
        >
          {waitlistHeroCTA.text}
        </button>
      </div>
    </div>
  );
}
